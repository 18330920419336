import { gql } from '@apollo/client'

export const QUERY_PAGE = gql`
  query Page($id: String!) {
    page(id: $id) {
      name
      path
      showPhoneNumber
      pageDisclosure {
        json
        links {
          entries {
            block {
              sys {
                id
              }
              __typename
              ... on ContentTypeRichText {
                name
                textContent {
                  json
                }
                uiComponent
                fieldName
                variant
              }
            }
          }
          assets {
            hyperlink {
              sys {
                id
              }
              fileName
              url
            }
          }
        }
      }
      pageConfig
      experiment {
        featureFlagId
        pageLevelExperiment
        name
      }
      experimentVariation
      associatedProduct
      pageMetaTitle
      metaDescription
      canonicalLinkOverride
    }
  }
`
export const QUERY_PAGE_SECTIONS = gql`
  query Page($id: String!) {
    page(id: $id) {
      sectionsCollection {
        items {
          ... on PageSection {
            sys {
              id
            }
            name
            fieldName
            uiComponent
            experiment {
              featureFlagId
              pageLevelExperiment
              name
            }
            experimentVariation
            sys {
              id
            }
            additionalConfiguration {
              jsonContent
            }
            lazyLoad
            relatedContentCollection {
              items {
                ... on LongFormContent {
                  sys {
                    id
                  }
                  __typename
                  slug
                  title
                  primaryCategory
                  mainImage {
                    ... on MediaField {
                      mediaAltText
                      mediaContent {
                        url
                        fileName
                        height
                        width
                      }
                    }
                    ... on VideoPlayer {
                      fieldName
                      uiComponent
                      source
                      thumbnail {
                        url
                        fileName
                        height
                        width
                      }
                      altText
                      autoplay
                    }
                  }
                  metaDescription
                  author {
                    authorName
                    authorByline
                    slug
                    authorPhoto {
                      fileName
                      url
                      size
                    }
                  }
                  hook
                  publishDate
                }
                ... on PressStory {
                  sys {
                    id
                  }
                  __typename
                  slug
                  title
                  primaryCategory
                  publishDate
                  externalLink
                  storyType
                }
                ... on ExternalLink {
                  sys {
                    id
                  }
                  __typename
                  title
                  subTitle
                  link
                  category
                  categoryLink
                  image {
                    url
                    fileName
                    height
                    width
                  }
                  ctaText
                }
              }
            }
            event
          }
        }
      }
    }
  }
`
export const MOLO_SECTION = gql`
  query MoloSection($id: String!) {
    moloSectionFeature: moloSectionFeature(id: $id) {
      title {
        json
        links {
          entries {
            inline {
              ... on StyledText {
                sys {
                  id
                }
                plainText
                styledText
                variation
                identifier
              }
            }
          }
        }
      }
      header {
        json
      }
      content {
        json
      }
      link {
        fieldName
        linkText {
          json
        }
        linkHref
        event
      }
      linkAndroid {
        ... on LInk {
          uiComponent
          fieldName
          linkText {
            json
          }
          linkHref
          event
        }
      }
      linkApple {
        ... on LInk {
          uiComponent
          fieldName
          linkText {
            json
          }
          linkHref
          event
        }
      }
      imageApple {
        url
        fileName
        height
        width
      }
      imageAndroid {
        url
        fileName
        height
        width
      }
      imageBackground {
        url
        fileName
        height
        width
      }
      imageTop {
        url
        fileName
        height
        width
      }
      configObject
    }
  }
`
export const PAGE_SECTION = gql`
  query PageSection($id: String!) {
    pageSection: pageSection(id: $id) {
      event
      sys {
        id
      }
      uiComponent
      experiment {
        featureFlagId
        pageLevelExperiment
        name
      }
      experimentVariation
      additionalConfiguration {
        jsonContent
      }
      relatedContentCollection {
        total
      }
      sectionContentsCollection {
        items {
          ... on EmailForm {
            sys {
              id
            }
            name
            titleText
            mailTopics
            sentTitle
            sentError
          }
          ... on StandardCard {
            sys {
              id
            }
          }
          ... on MoloSectionFeature {
            __typename
            sys {
              id
            }
          }
          ... on ColorRichText {
            __typename
            sys {
              id
            }
            textContent {
              __typename
            }
            name
            fieldName
            uiComponent
          }
          ... on MediaField {
            fieldName
            uiComponent
            mediaAltText
            mediaContent {
              url
              height
              width
            }
            middleMediaContent {
              url
              height
              width
            }
            mobileMediaContent {
              url
              height
              width
            }
          }
          ... on JsonField {
            fieldName
            uiComponent
            jsonContent
          }
          ... on WwwQuestionGroup {
            fieldName
            color
            mainIcon {
              title
              url
              height
              width
            }
            secondaryIcon {
              title
              url
              height
              width
            }
            title
            questionsCollection {
              items {
                __typename
                sys {
                  id
                }
              }
            }
          }
          ... on StrictRichText {
            fieldName
            component
            textContent {
              json
            }
            variantXxl
            variantXl
            variantLg
            variantMd
            variantSm
            variantXs
            weightXxl
            weightXl
            weightLg
            weightMd
            weightSm
            weightXs
          }
          ... on DownloableAttachment {
            titleText
            description {
              json
            }
            image {
              url
              fileName
              height
              width
            }
            downloadFile {
              url
              fileName
              height
              width
            }
            ctaField
          }
          ... on LInk {
            fieldName
            uiComponent
            text
            linkText {
              json
            }
            linkHref
            event
          }
          ... on StandardCard {
            fieldName
            iconCardItemCollection {
              items {
                ... on IconCardItem {
                  iconName
                  iconColor
                  description
                }
              }
            }
          }
          ... on ContentTypeRichText {
            __typename
            sys {
              id
            }
            textContent {
              __typename
            }
            name
            fieldName
            uiComponent
          }
          ... on WwwCompareItems {
            fieldName
            title
            subTitle
            config
            icon {
              url
              fileName
              height
              width
            }
          }
          ... on VideoPlayer {
            fieldName
            uiComponent
            source
            thumbnail {
              url
              fileName
              height
              width
            }
            altText
            autoplay
          }
          ... on Grid {
            fieldName
            columns
            uiComponent
            gridItemsCollection {
              items {
                ... on Grid {
                  __typename
                  sys {
                    id
                  }
                }
                ... on ContentTypeRichText {
                  fieldName
                  textContent {
                    json
                  }
                }
                ... on VideoPlayer {
                  fieldName
                  source
                  altText
                  autoplay
                }
                ... on MediaField {
                  __typename
                  sys {
                    id
                  }
                }
                ... on CardMarkers {
                  sys {
                    id
                  }
                }
                ... on StoryBlockCard {
                  sys {
                    id
                  }
                }
                ... on StandardCard {
                  sys {
                    id
                  }
                }
                ... on MemberStory {
                  sys {
                    id
                  }
                }
              }
            }
          }
          ... on SectionTheme {
            fieldName
            theme
          }
          ... on Carousel {
            fieldName
            uiComponent
            carouselItemsCollection {
              items {
                ... on StandardCard {
                  sys {
                    id
                  }
                }
                ... on Grid {
                  __typename
                  sys {
                    id
                  }
                }
              }
            }
          }
          ... on Faqs2 {
            fieldName
            sys {
              id
            }
          }
          ... on PageSection {
            sys {
              id
            }
          }
        }
      }
    }
  }
`
export const PAGE_SECTION_RELATED_CONTENT = gql`
  query PageSection($id: String!) {
    pageSection: pageSection(id: $id) {
      sys {
        id
      }
      relatedContentCollection {
        total
        items {
          ... on LongFormContent {
            sys {
              id
            }
            __typename
            slug
            title
            contentType
            primaryCategory
            mainImage {
              ... on MediaField {
                mediaAltText
                mediaContent {
                  url
                  fileName
                  height
                  width
                }
              }
              ... on VideoPlayer {
                fieldName
                uiComponent
                source
                thumbnail {
                  url
                  fileName
                  height
                  width
                }
                altText
                autoplay
              }
            }
            metaDescription
            author {
              authorName
              authorByline
              slug
              authorPhoto {
                fileName
                url
                size
              }
            }
            hook
            publishDate
          }
          ... on PressStory {
            sys {
              id
            }
            __typename
            slug
            title
            primaryCategory
            publishDate
            externalLink
            storyType
          }
        }
      }
    }
  }
`

export const CARD_MARKERS = gql`
  query CardMarkers($id: String!) {
    cardMarkers: cardMarkers(id: $id) {
      eyebrow {
        json
      }
      title {
        json
      }
      subtitle {
        json
        links {
          entries {
            inline {
              ... on StyledText {
                sys {
                  id
                }
                plainText
                styledText
                variation
                identifier
              }
            }
          }
        }
      }
      image {
        title
        url
        fileName
        height
        width
      }
    }
  }
`

export const STANDARD_CARD = gql`
  query StandardCard($id: String!) {
    standardCard: standardCard(id: $id) {
      fieldName
      uiComponent
      cardBadgeImage {
        title
        url
        fileName
        height
        width
      }
      eyebrow
      cardTitleText {
        json
        links {
          entries {
            inline {
              ... on StyledText {
                sys {
                  id
                }
                plainText
                styledText
                variation
                identifier
              }
            }
          }
        }
      }
      cardSubtitleText {
        json
      }
      cardImage {
        url
        fileName
        height
        width
      }
      cardImageResponsive {
        url
        fileName
        height
        width
      }
      cardImageAlternative {
        url
        fileName
        height
        width
      }
      graphicComponent {
        name
        component
        jsonConfig
        resourcesCollection {
          items {
            ... on Asset {
              url
              fileName
            }
          }
        }
      }
      cardText {
        json
        links {
          entries {
            inline {
              ... on StyledText {
                sys {
                  id
                }
                plainText
                styledText
                variation
                identifier
              }
              ... on VideoModal {
                sys {
                  id
                }
                altText
                title
                source
                autoplay
                buttonCta
                videoPlayer {
                  fieldName
                  uiComponent
                  source
                  thumbnail {
                    url
                    fileName
                    height
                    width
                  }
                  altText
                  autoplay
                }
              }
            }
          }
        }
      }
      cardJson
      imageApple {
        url
        fileName
        height
        width
      }
      imageAndroid {
        url
        fileName
        height
        width
      }
      iconCardItemCollection {
        items {
          ... on IconCardItem {
            iconName
            iconColor
            description
          }
        }
      }
      extraComponentsCollection {
        items {
          ... on StandardCard {
            fieldName
            cardImage {
              url
              fileName
              height
              width
            }
            cardText {
              json
            }
          }
          ... on LInk {
            uiComponent
            fieldName
            linkText {
              json
            }
            linkHref
            event
          }
        }
      }
      cardLink {
        ... on LInk {
          uiComponent
          name
          fieldName
          linkText {
            json
          }
          linkHref
          event
        }
        ... on DownloableAttachment {
          titleText
          description {
            json
          }
          image {
            url
            fileName
            height
            width
          }
          downloadFile {
            url
            fileName
            height
            width
          }
          ctaField
        }
      }
      secondaryCardLink {
        ... on LInk {
          uiComponent
          fieldName
          linkText {
            json
          }
          linkHref
          event
        }
      }
      linkAndroid {
        ... on LInk {
          uiComponent
          fieldName
          linkText {
            json
          }
          linkHref
          event
        }
      }
      linkApple {
        ... on LInk {
          uiComponent
          fieldName
          linkText {
            json
          }
          linkHref
          event
        }
      }
    }
  }
`
export const MEMBER_STORY = gql`
  query MemberStory($id: String!) {
    memberStory: memberStory(id: $id) {
      title {
        json
      }
      disclaimer {
        json
      }
      message {
        json
      }
      image {
        url
        fileName
        height
        width
      }
      videoDialog {
        title
        source
        altText
        autoplay
        videoPlayer {
          fieldName
          uiComponent
          source
          thumbnail {
            url
            fileName
            height
            width
          }
          altText
          autoplay
        }
        buttonCta
      }
    }
  }
`
export const GRID = gql`
  query Grid($id: String!) {
    grid: grid(id: $id) {
      fieldName
      gridItemsCollection {
        items {
          ... on MediaField {
            fieldName
            uiComponent
            mediaAltText
            mediaContent {
              url
              height
              width
            }
          }
          ... on ColorRichText {
            fieldName
            uiComponent
            textContent {
              json
            }
            fontColor
            position
            shadow
          }

          ... on ContentTypeRichText {
            fieldName
            uiComponent
            textContent {
              json
            }
          }

          ... on StandardCard {
            fieldName
            uiComponent
            cardBadgeImage {
              title
              url
              fileName
              height
              width
            }
            eyebrow
            cardTitleText {
              json
            }
            cardSubtitleText {
              json
            }
            cardImage {
              url
              fileName
              height
              width
            }
            cardImageResponsive {
              url
              fileName
              height
              width
            }
            cardImageAlternative {
              url
              fileName
              height
              width
            }
            graphicComponent {
              name
              component
              jsonConfig
            }
            cardText {
              json
            }
            cardJson
            imageApple {
              url
              fileName
              height
              width
            }
            imageAndroid {
              url
              fileName
              height
              width
            }
            iconCardItemCollection {
              items {
                ... on IconCardItem {
                  iconName
                  iconColor
                  description
                }
              }
            }
            cardLink {
              ... on LInk {
                uiComponent
                fieldName
                linkText {
                  json
                }
                linkHref
                event
              }
            }
            secondaryCardLink {
              ... on LInk {
                uiComponent
                fieldName
                linkText {
                  json
                }
                linkHref
                event
              }
            }
            linkAndroid {
              ... on LInk {
                uiComponent
                fieldName
                linkText {
                  json
                }
                linkHref
                event
              }
            }
            linkApple {
              ... on LInk {
                uiComponent
                fieldName
                linkText {
                  json
                }
                linkHref
                event
              }
            }
          }
        }
      }
    }
  }
`
export const MEDIA_FIELD = gql`
  query MediaField($id: String!) {
    mediaField: mediaField(id: $id) {
      fieldName
      mediaAltText
      mediaContent {
        url
        fileName
        height
        width
      }
    }
  }
`

export const STORY_BLOCK_CARD = gql`
  query StoryBlockCard($id: String!) {
    storyBlockCard: storyBlockCard(id: $id) {
      name
      eyebrow
      title
      text {
        json
      }
      image {
        url
        fileName
        height
        width
      }
      altText
      link {
        ... on LInk {
          fieldName
          linkText {
            json
          }
          linkHref
          event
        }
      }
      author
    }
  }
`

export const CONSUMER_BLOG = (limit, skip) => {
  return gql`
    query consumerBlog($filter: ConsumerBlogFilter!){
      consumerBlogCollection(limit: ${limit}, skip: ${skip}, order: publishDate_DESC, where: $filter) {
        total
        items {
          slug
          title
          mainImage{
            fieldName
            mediaContent {
                url
                height
                width
              }
              middleMediaContent {
                url
                height
                width
              }
              mobileMediaContent {
                url
                height
                width
              }
          }
          hook
          primaryCategory
        }
      }
    }
    `
}

export const CORPORATE_BLOG = (limit, skip) => {
  return gql`
    query corporateBlog($filter: CorporateBlogFilter!){
      corporateBlogCollection(limit: ${limit}, skip: ${skip}, order: publishDate_DESC, where: $filter) {
        total
        items {
          slug
          title
          mainImage{
            fieldName
            mediaContent {
                url
                height
                width
              }
              middleMediaContent {
                url
                height
                width
              }
              mobileMediaContent {
                url
                height
                width
              }
          }
          hook
          primaryCategory
        }
      }
    }
    `
}

export const ARTICLE = (limit, skip, orderBy) => {
  let orderParam = 'publishDate_DESC'
  // TODO: TBD
  if (orderBy === 'popular') orderParam = 'title_ASC'
  return gql`
    query article($filter: ArticleFilter!){
        articleCollection(limit: ${limit}, skip: ${skip}, order: ${orderParam}, where: $filter) {
        total
        items {
            slug
            title
            mainImage {
              __typename
              ...on MediaField {
                fieldName
                mediaContent {
                  url
                  height
                  width
                }
                middleMediaContent {
                  url
                  height
                  width
                }
                mobileMediaContent {
                  url
                  height
                  width
                }
              }
              ... on VideoPlayer {
                fieldName
                uiComponent
                source
                thumbnail {
                  url
                  fileName
                  height
                  width
                }
                altText
                autoplay
              }
            }
        hook
        primaryCategory
      }
    }
  }
  `
}

export const LONG_FORM_COLLECTION = () => {
  return gql`
    query longFormContent($where: LongFormContentFilter) {
      longFormContentCollection(where: $where) {
        total
        items {
          slug
          title
          mainImage {
            __typename
            ... on MediaField {
              fieldName
              mediaContent {
                url
                height
                width
              }
              middleMediaContent {
                url
                height
                width
              }
              mobileMediaContent {
                url
                height
                width
              }
            }
            ... on VideoPlayer {
              fieldName
              uiComponent
              source
              thumbnail {
                url
                fileName
                height
                width
              }
              altText
              autoplay
            }
          }
          hook
          primaryCategory
        }
      }
    }
  `
}

export const LONG_FORM_CONTENT = (limit, skip, orderBy) => {
  let orderParam = 'publishDate_DESC'
  // TODO: TBD
  if (orderBy === 'popular') orderParam = 'efficiencyScore_DESC'
  return gql`
    query longFormContent($filter: LongFormContentFilter!){
        longFormContentCollection(limit: ${limit}, skip: ${skip}, order: ${orderParam}, where: $filter) {
        total
        items {
            website
            slug
            title
            mainImage {
              __typename
              ...on MediaField {
                fieldName
                mediaContent {
                  url
                  height
                  width
                }
                middleMediaContent {
                  url
                  height
                  width
                }
                mobileMediaContent {
                  url
                  height
                  width
                }
              }
              ... on VideoPlayer {
                fieldName
                uiComponent
                source
                thumbnail {
                  url
                  fileName
                  height
                  width
                }
                altText
                autoplay
              }
            }
        hook
        primaryCategory
        efficiencyScore
      }
    }
  }
  `
}

/**
 * Gets all articles, corporate blogs and consumer blogs for an authorName including totals and data
 * metadata for server-side pagination
 */
export const ARTICLES_BY_AUTHOR = gql`
  query ArticleListByAuthor($authorName: String!) {
    longFormContentCollection(where: { author: { authorName: $authorName } }) {
      total
      items {
        title
        publishDate
        mainImage {
          ... on MediaField {
            mediaContent {
              url
              fileName
              height
              width
            }
          }
        }
        hook
        primaryCategory
        slug
      }
    }
  }
`

export const PRESS_STORY = (limit, skip) => {
  return gql`
    query pressStory($filter: PressStoryFilter!){
      pressStoryCollection(limit: ${limit}, skip: ${skip}, order: publishDate_DESC, where: $filter) {
        total
        items {
          slug
          title
          publishDate
          externalLink
          storyType
        }
      }
    }
    `
}

export const CONTENT_TYPE_RICH_TEXT = gql`
  query ContentTypeRichText($id: String!) {
    contentTypeRichText: contentTypeRichText(id: $id) {
      name
      fieldName
      uiComponent
      textContent {
        json
        links {
          entries {
            inline {
              ... on StyledText {
                sys {
                  id
                }
                plainText
                styledText
                variation
                identifier
              }
              ... on VideoModal {
                sys {
                  id
                }
                altText
                title
                source
                autoplay
                buttonCta
                videoPlayer {
                  fieldName
                  uiComponent
                  source
                  thumbnail {
                    url
                    fileName
                    height
                    width
                  }
                  altText
                  autoplay
                }
              }
            }
          }
          assets {
            hyperlink {
              sys {
                id
              }
              url
              title
              description
              contentType
              fileName
            }
          }
        }
      }
    }
  }
`
export const COLOR_RICH_TEXT = gql`
  query ColorRichText($id: String!) {
    colorRichText: colorRichText(id: $id) {
      fieldName
      uiComponent
      textContent {
        json
        links {
          entries {
            inline {
              ... on StyledText {
                sys {
                  id
                }
                plainText
                styledText
                variation
                identifier
              }
            }
          }
        }
      }
      fontColor
      position
      shadow
    }
  }
`
export const JSON_FIELD = gql`
  query {
    jsonFieldCollection(where: { name: "Global Lazy Load Sections Mapping" }) {
      items {
        jsonContent
      }
    }
  }
`
export const LANDING_PAGE = gql`
  query LandingPage($id: String!) {
    landingPage(id: $id) {
      name
      path
      showPhoneNumber
      phoneOverride
      pageDisclosure {
        json
      }
      pageConfig
      experiment {
        featureFlagId
        pageLevelExperiment
        name
      }
      experimentVariation
      associatedProduct
      pageMetaTitle
      metaDescription
      canonicalLinkOverride
      sectionsCollection {
        items {
          ... on PageSection {
            sys {
              id
            }
            name
            fieldName
            uiComponent
            experiment {
              featureFlagId
              pageLevelExperiment
              name
            }
            experimentVariation
            sys {
              id
            }
            additionalConfiguration {
              jsonContent
            }
            lazyLoad
            relatedContentCollection {
              items {
                ... on LongFormContent {
                  sys {
                    id
                  }
                  __typename
                  slug
                  title
                  primaryCategory
                  mainImage {
                    ... on MediaField {
                      mediaAltText
                      mediaContent {
                        url
                        fileName
                        height
                        width
                      }
                    }
                    ... on VideoPlayer {
                      fieldName
                      uiComponent
                      source
                      thumbnail {
                        url
                        fileName
                        height
                        width
                      }
                      altText
                      autoplay
                    }
                  }
                  hook
                  publishDate
                }
                ... on ConsumerBlog {
                  sys {
                    id
                  }
                  __typename
                  slug
                  title
                  primaryCategory
                  mainImage {
                    mediaAltText
                    mediaContent {
                      url
                      fileName
                      height
                      width
                    }
                  }
                  hook
                  publishDate
                }
                ... on CorporateBlog {
                  sys {
                    id
                  }
                  __typename
                  slug
                  title
                  primaryCategory
                  mainImage {
                    mediaAltText
                    mediaContent {
                      url
                      fileName
                      height
                      width
                    }
                  }
                  hook
                  publishDate
                }
                ... on PressStory {
                  sys {
                    id
                  }
                  __typename
                  slug
                  title
                  primaryCategory
                  publishDate
                  externalLink
                  storyType
                }
                ... on ExternalLink {
                  sys {
                    id
                  }
                  __typename
                  title
                  subTitle
                  link
                  category
                  categoryLink
                  image {
                    url
                    fileName
                    height
                    width
                  }
                  ctaText
                }
              }
            }
          }
        }
      }
    }
  }
`

export const ADVERTORIAL_COLLECTION = gql`
  query Advertorial($filter: AdvertorialFilter!) {
    advertorialCollection(where: $filter, limit: 1) {
      total
      items {
        website
        pageDisclosure {
          json
        }
        pageMetaTitle
        metaDescription
        publishDate
        canonicalLinkOverride
        associatedProduct
        pageMetaDataCollection {
          items {
            name
            content
          }
        }
        title
        showPhoneNumber
        phoneOverride
        pageConfig
        readTime
        author {
          authorName
          authorByline
          slug
          authorPhoto {
            fileName
            url
            size
          }
        }
        reviewedBy {
          authorName
          authorByline
          slug
          authorPhoto {
            fileName
            url
            size
          }
        }
        mainImage {
          ... on MediaField {
            fieldName
            uiComponent
            mediaAltText
            mediaContent {
              url
              height
              width
            }
            middleMediaContent {
              url
              height
              width
            }
            mobileMediaContent {
              url
              height
              width
            }
          }
        }
        mainBody {
          json
          links {
            assets {
              block {
                ... on Asset {
                  sys {
                    id
                  }
                  url
                  fileName
                }
              }
            }
            entries {
              inline {
                sys {
                  id
                }
                __typename
                ... on LInk {
                  uiComponent
                  fieldName
                  linkText {
                    json
                  }
                  linkHref
                  event
                }
                ... on ArticleCtaCard {
                  title {
                    json
                  }
                  subtitle {
                    json
                  }
                  link {
                    uiComponent
                    fieldName
                    linkText {
                      json
                    }
                    linkHref
                    event
                  }
                }
                ... on VideoPlayer {
                  fieldName
                  uiComponent
                  source
                  thumbnail {
                    url
                    fileName
                    height
                    width
                  }
                  altText
                  autoplay
                }
              }
              block {
                sys {
                  id
                }
                __typename
                ... on LInk {
                  uiComponent
                  fieldName
                  linkText {
                    json
                  }
                  linkHref
                  event
                }
                ... on ArticleCtaCard {
                  title {
                    json
                  }
                  subtitle {
                    json
                  }
                  link {
                    uiComponent
                    fieldName
                    linkText {
                      json
                    }
                    linkHref
                    event
                  }
                }
                ... on VideoPlayer {
                  fieldName
                  uiComponent
                  source
                  thumbnail {
                    url
                    fileName
                    height
                    width
                  }
                  altText
                  autoplay
                }
              }
            }
          }
        }
        faq {
          name
          faqSetsCollection {
            items {
              fieldName
              answer {
                json
              }
              question {
                json
              }
              sys {
                id
              }
            }
          }
        }
        multiSlider {
          ... on PageSection {
            sys {
              id
            }
            sectionContentsCollection(limit: 3) {
              items {
                ... on Carousel {
                  carouselItemsCollection(limit: 1) {
                    items {
                      ... on Grid {
                        gridItemsCollection(limit: 10) {
                          items {
                            ... on MediaField {
                              mediaAltText
                              mediaContent {
                                url
                                fileName
                                height
                                width
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            additionalConfiguration {
              jsonContent
            }
          }
        }
      }
    }
  }
`

export const EMAIL_FORM = gql`
  query ColorRichText($id: String!) {
    emailForm: emailForm(id: $id) {
      mailTopics
    }
  }
`

export const QUESTION = gql`
  query Question($id: String!) {
    question: wwwQuestion(id: $id) {
      title
      moreInfoText {
        json
      }
      answerList {
        jsonContent
      }
      questionType
      valueType
    }
  }
`

export const QUERY_RESULTS = gql`
  query Results($id: String!) {
    PageSection: pageSection(id: $id) {
      sectionContentsCollection {
        items {
          ... on WwwDebtQuizResponse {
            name
            sys {
              id
            }
            maxPercentValue
            sectionsCollection {
              items {
                ... on PageSection {
                  sys {
                    id
                  }
                  additionalConfiguration {
                    jsonContent
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const RESULT_PAGE_DISCLOSURE = gql`
  query ResultsDisclousure($id: String!) {
    DebtQuizResponse: wwwDebtQuizResponse(id: $id) {
      pageDisclosure {
        json
        links {
          entries {
            block {
              sys {
                id
              }
              __typename
              ... on ContentTypeRichText {
                name
                textContent {
                  json
                }
                uiComponent
                fieldName
                variant
              }
            }
          }
        }
      }
    }
  }
`

export const FAQ_DATA = gql`
  query faqData($id: String!) {
    data: faqs2(id: $id) {
      fieldName
      faqSetsCollection {
        items {
          sys {
            id
          }
          fieldName
          question {
            json
          }
          answer {
            json
          }
          formConfig
          miniCalculatorConfig
          articlesCollection {
            items {
              ... on LongFormContent {
                slug
                title
                mainImage {
                  ... on MediaField {
                    mediaAltText
                    mediaContent {
                      url
                      fileName
                      height
                      width
                    }
                  }
                  ... on VideoPlayer {
                    fieldName
                    uiComponent
                    source
                    thumbnail {
                      url
                      fileName
                      height
                      width
                    }
                    altText
                    autoplay
                  }
                }
                author {
                  authorName
                  authorByline
                  slug
                  authorPhoto {
                    fileName
                    url
                    size
                  }
                }
                hook
                publishDate
                primaryCategory
              }
            }
          }
        }
      }
      jsonLd
    }
  }
`

export const ARTICLE_CATEGORY_SECTION = gql`
  query PageSection($filter: PageSectionFilter!) {
    pageSectionCollection(limit: 4, where: $filter) {
      total
      items {
        sys {
          id
        }
        fieldName
        uiComponent
        experiment {
          featureFlagId
          pageLevelExperiment
          name
        }
        experimentVariation
        additionalConfiguration {
          jsonContent
        }
        sectionContentsCollection {
          items {
            ... on ContentTypeRichText {
              fieldName
              textContent {
                json
              }
            }
            ... on LInk {
              uiComponent
              fieldName
              linkText {
                json
              }
              linkHref
            }
          }
        }
        relatedContentCollection {
          items {
            ... on LongFormContent {
              sys {
                id
              }
              __typename
              slug
              title
              primaryCategory
              mainImage {
                ... on MediaField {
                  mediaAltText
                  mediaContent {
                    url
                    fileName
                    height
                    width
                  }
                }
                ... on VideoPlayer {
                  fieldName
                  uiComponent
                  source
                  thumbnail {
                    url
                    fileName
                    height
                    width
                  }
                  altText
                  autoplay
                }
              }
              hook
              publishDate
              author {
                authorName
                authorByline
                slug
                authorPhoto {
                  fileName
                  url
                  size
                }
              }
            }
          }
        }
      }
    }
  }
`
export const ARTICLE_NAV_SECTION = gql`
  query PageSection($filter: PageSectionFilter!) {
    pageSectionCollection(limit: 1, where: $filter) {
      total
      items {
        website
        sys {
          id
        }
        fieldName
        uiComponent
        experiment {
          featureFlagId
          pageLevelExperiment
          name
        }
        experimentVariation
        additionalConfiguration {
          jsonContent
        }
        sectionContentsCollection {
          items {
            ... on ContentTypeRichText {
              fieldName
              textContent {
                json
              }
            }
            ... on PageSection {
              fieldName
              sectionContentsCollection {
                items {
                  ... on ContentTypeRichText {
                    fieldName
                    textContent {
                      json
                    }
                  }
                  ... on LInk {
                    uiComponent
                    fieldName
                    linkText {
                      json
                    }
                    linkHref
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const LEARN_CATEGORIES_FULL = gql`
  query ($where: LongFormContentFilter) {
    longFormContentCollection(limit: 1000, where: $where) {
      items {
        contentType
        primaryCategory
        secondaryCategory
      }
    }
  }
`
