import { getContentfulClient } from './client'
import {
  CONTENT_TYPES,
  NESTED_REFERENCE_RESOLUTION_DEPTH,
  LANGUAGES,
  PAGE_LIMIT_DEFAULT,
  AUTHOR_LIMIT_DEFAULT,
  AUTHOR_LIMIT_ARTICLES_DEFAULT,
  ARTICLES_CONTENT_TYPES,
} from './constants'
import { normalizeContent } from 'utils/shared/hacks'
import apolloClient from './gql/apollo-client'

//Uppercase snake case seems to be standard for QUERY naming
import {
  ARTICLES_BY_AUTHOR,
  EMAIL_FORM,
  CARD_MARKERS,
  GRID,
  MEDIA_FIELD,
  MEMBER_STORY,
  MOLO_SECTION,
  PAGE_SECTION,
  FAQ_DATA,
  QUERY_PAGE,
  STANDARD_CARD,
  STORY_BLOCK_CARD,
  PRESS_STORY,
  CONTENT_TYPE_RICH_TEXT,
  COLOR_RICH_TEXT,
  JSON_FIELD,
  LANDING_PAGE,
  QUESTION,
  ARTICLE_CATEGORY_SECTION,
  ARTICLE_NAV_SECTION,
  PAGE_SECTION_RELATED_CONTENT,
  LEARN_CATEGORIES_FULL,
  LONG_FORM_CONTENT,
  QUERY_PAGE_SECTIONS,
  ADVERTORIAL_COLLECTION,
} from './gql/query'

import { detectCrawler } from 'utils/crawler'
import { getExperiment } from 'utils/experiments'
import { getSlug } from 'utils/conversions'
import { randomBytes } from 'crypto'
import { sendApolloAlert } from 'services/slack/apolloError'
const CONTENTFUL_LIMIT = 1000

import { getPageContentTypes, tenant2, WfsPage } from 'utils/environmentConfig'

const { websiteField } = getPageContentTypes()

export const createRandomKey = () => {
  // Generate a random 256-bit key
  // Convert the key to a base64-encoded string
  return randomBytes(32).toString('base64')
}

//Pulls all entries 100 at a time.
export const getAllEntriesFromType = async (environmentInstanceSource, type) => {
  let entryCount = await environmentInstanceSource.getEntries({
    content_type: type,
    include: 10,
    limit: 100,
  })
  entryCount = entryCount.total
  let entries = []
  for (let i = 0; i < Math.ceil(entryCount / 100); i++) {
    let entryArr = await environmentInstanceSource.getEntries({
      content_type: type,
      limit: 100,
      skip: i * 100,
    })
    entryArr = entryArr.items
    entries = entries.concat(entryArr)
  }
  return entries
}

export const queryApollo = async (query, variables = {}) => {
  try {
    return await apolloClient().query({
      query,
      variables: variables,
    })
  } catch (error) {
    sendApolloAlert(error)
  }
}

const queryApolloById = async (query, id) => {
  try {
    return apolloClient().query({
      query,
      variables: {
        id,
      },
    })
  } catch (error) {
    sendApolloAlert(error)
  }
}

const queryApolloByFilter = async (query, filter) => {
  try {
    return await apolloClient().query({
      query,
      variables: {
        filter,
      },
    })
  } catch (error) {
    sendApolloAlert(error)
  }
}

export const fetchHeaderByPage = async ({
  pageRoute,
  featureToggleObject,
  queryParameters,
  req,
}) => {
  let headerData
  if (pageRoute.startsWith('/l/')) {
    headerData = await getContentfulClient({}).getEntries({
      content_type: CONTENT_TYPES.LANDING,
      include: NESTED_REFERENCE_RESOLUTION_DEPTH,
      'fields.path': pageRoute.replace('/l/', ''),
      'fields.website': websiteField,
    })
  } else if (pageRoute.startsWith('/a/')) {
    headerData = await getContentfulClient({}).getEntries({
      content_type: CONTENT_TYPES.ADVERTORIAL,
      include: NESTED_REFERENCE_RESOLUTION_DEPTH,
      'fields.slug': pageRoute.replace('/a/', ''),
      'fields.website': websiteField,
    })
  } else {
    headerData = await getContentfulClient({}).getEntries({
      content_type: CONTENT_TYPES.PAGE,
      include: NESTED_REFERENCE_RESOLUTION_DEPTH,
      'fields.path': pageRoute,
      'fields.website': websiteField,
    })
  }
  const isCrawler = detectCrawler(req)
  let experiment = getExperiment({
    featureToggleObject,
    queryParameters,
    isCrawler,
    data: headerData,
  })
  const header = headerData.items.filter((e) => {
    return e.fields?.experimentVariation === experiment || !e.fields?.experimentVariation
  })
  return header[0]?.fields?.header
}

export const fetchPageEntry = async ({ path, queryParameters = {} }) => {
  return getContentfulClient({ queryParameters }).getEntries({
    content_type: CONTENT_TYPES.PAGE,
    'fields.path': path,
    'fields.website': websiteField,
  })
}

export const fetchLandingPageEntry = async ({ path, queryParameters = {} }) => {
  return getContentfulClient(queryParameters).getEntries({
    content_type: CONTENT_TYPES.LANDING,
    'fields.path': path,
    'fields.website': websiteField,
  })
}

export const fetchPermalinkAsset = async (prettyAssetSlug, queryParameters = {}) => {
  return getContentfulClient(queryParameters).getEntries({
    content_type: CONTENT_TYPES.PERMALINK_MEDIA,
    'fields.prettyAssetSlug': prettyAssetSlug,
  })
}

export const fetchEntry = async (slug, contentType, queryParameters = {}) => {
  const entry = await getContentfulClient(queryParameters).getEntries({
    content_type: contentType,
    'fields.slug': slug,
    include: NESTED_REFERENCE_RESOLUTION_DEPTH,
    locale: LANGUAGES.DEFAULT,
  })
  return normalizeContent(entry)
}

export const fetchPostsCloserStatement = async (queryParameters = {}) => {
  return getContentfulClient(queryParameters).getEntries({
    content_type: 'pageSection',
    'fields.name': 'Posts Closer Statement',
    include: NESTED_REFERENCE_RESOLUTION_DEPTH,
    locale: LANGUAGES.DEFAULT,
  })
}

export const fetchAuthorCloserStatement = async (queryParameters = {}) => {
  return getContentfulClient(queryParameters).getEntries({
    content_type: 'pageSection',
    'fields.name': 'Author Closer Statement',
    include: NESTED_REFERENCE_RESOLUTION_DEPTH,
    locale: LANGUAGES.DEFAULT,
  })
}

export const fetchByNameEntry = async (content_type, name, queryParameters = {}) => {
  return getContentfulClient(queryParameters).getEntries({
    'fields.name': name,
    include: NESTED_REFERENCE_RESOLUTION_DEPTH,
    content_type,
  })
}

export const fetchPressEntry = async (slug, queryParameters = {}) => {
  return fetchEntry(slug, CONTENT_TYPES.PRESS_STORY, queryParameters)
}

export const fetchArticleEntry = async (slug, queryParameters = {}) => {
  return fetchEntry(slug, CONTENT_TYPES.ARTICLE, queryParameters)
}

export const fetchLongFormEntry = async (slug, queryParameters = {}) => {
  const entry = await getContentfulClient({ queryParameters }).getEntries({
    content_type: CONTENT_TYPES.LONG_FORM_CONTENT,
    'fields.slug': slug,
    'fields.website': websiteField,
    include: NESTED_REFERENCE_RESOLUTION_DEPTH,
  })
  return normalizeContent(entry)
}

export const fetchAdvertorialEntry = async (slug, queryParameters = {}) => {
  const entry = await getContentfulClient({ queryParameters }).getEntries({
    content_type: CONTENT_TYPES.ADVERTORIAL,
    'fields.slug': slug,
    'fields.website': websiteField,
    include: NESTED_REFERENCE_RESOLUTION_DEPTH,
  })
  return normalizeContent(entry)
}

export const fetchCorporateBlogEntry = async (slug, queryParameters = {}) => {
  return fetchEntry(slug, CONTENT_TYPES.CORPORATE_BLOG, queryParameters)
}

export const fetchConsumerBlogEntry = async (slug, queryParameters = {}) => {
  return fetchEntry(slug, CONTENT_TYPES.CONSUMER_BLOG, queryParameters)
}

export const fetchLandingEntry = async (slug) => {
  const { data } = await queryApolloByFilter(ADVERTORIAL_COLLECTION, {
    slug: slug,
    website: websiteField,
  })
  return normalizeContent(data?.advertorialCollection)
}

export const fetchBannerEntry = async (name, queryParameters = {}) => {
  return fetchByNameEntry(CONTENT_TYPES.FFAM_BANNER, name, queryParameters)
}

export const fetchHeader = async () => {
  let defaultHeader = 'Header'
  switch (process.env.NEXT_PUBLIC_SITE_ENVIRONMENT) {
    case WfsPage:
      defaultHeader = 'Default Header WFS'
      break
    case tenant2:
      defaultHeader = 'Default Header Website 2'
      break
    default:
      defaultHeader = 'Header'
      break
  }
  return fetchByNameEntry(CONTENT_TYPES.HEADER, defaultHeader)
}

export const fetchFooter = async (queryParameters = {}) => {
  let defaultFooter = 'Footer'
  switch (process.env.NEXT_PUBLIC_SITE_ENVIRONMENT) {
    case WfsPage:
      defaultFooter = 'Default Footer WFS'
      break
    case tenant2:
      defaultFooter = 'Default Footer Website 2'
      break
    default:
      defaultFooter = 'Footer'
      break
  }
  return fetchByNameEntry(CONTENT_TYPES.FOOTER, defaultFooter, queryParameters)
}

export const fetchLongFormContentType = async (
  content_type,
  pageLimit,
  page,
  filter,
  category,
  associatedProduct,
  keywords,
  filterFields = {},
  queryParameters = {}
) => {
  let searchObject = {
    ...filterFields,
    content_type: CONTENT_TYPES[content_type.split(' ').join('_').toUpperCase()], //this should be done in contenful
    order: '-fields.publishDate',
  }

  if (filter && filter !== '*') {
    searchObject = { ...searchObject, 'fields.storyType[match]': filter }
  }

  let secondSearchObject = searchObject
  if (category && category !== '*') {
    searchObject = { ...searchObject, 'fields.primaryCategory[match]': category }
    secondSearchObject = { ...secondSearchObject, 'fields.secondaryCategory[match]': category }
  }

  if (associatedProduct && associatedProduct !== '*') {
    searchObject = { ...searchObject, 'fields.associatedProduct[match]': associatedProduct }
  }

  if (keywords) {
    searchObject = {
      ...searchObject,
      query: keywords,
    }
  }
  if (page > 0) {
    searchObject = { ...searchObject, skip: parseInt((page - 1) * pageLimit) }
  }

  const searchObjectWithLimit = { ...searchObject, limit: pageLimit }
  const secondSearchObjectWithLimit = { ...secondSearchObject, limit: pageLimit }

  let total = await getContentfulClient(queryParameters).getEntries(searchObject)
  total = total.total

  if (total == 0) {
    total = await getContentfulClient(queryParameters).getEntries(secondSearchObject)
    total = total.total
  }

  let articles = await getContentfulClient(queryParameters).getEntries(searchObjectWithLimit)
  articles = articles.items

  if (articles.length == 0) {
    articles = await getContentfulClient(queryParameters).getEntries(secondSearchObjectWithLimit)
    articles = articles.items
  }

  articles.sort(function (a, b) {
    return new Date(b.fields.publishDate).getTime() - new Date(a.fields.publishDate).getTime()
  })

  return { articles, total }
}

//fetch filtered articles sorted by publish date, if <3 stories match filters, reduce filter reqs
export const fetchRelatedArticles = async ({
  slug,
  content_type,
  storyType,
  primaryCategory,
  // TODO Remove safeguard for mainCategory since we don't need it in the future.
  mainCategory,
  queryParameters = {},
  limit = 3,
}) => {
  let baseFetchObject = {
    content_type,
    select:
      'fields.title,fields.slug,fields.mainImage,fields.author,fields.primaryCategory,fields.metaDescription',
    'fields.slug[ne]': slug,
    'fields.website': websiteField,
    order: 'fields.publishDate',
    limit,
    include: NESTED_REFERENCE_RESOLUTION_DEPTH,
    locale: LANGUAGES.DEFAULT,
  }

  if (storyType) {
    baseFetchObject = { ...baseFetchObject, 'fields.storyType': storyType }
  }

  //Filter based on storyType and primaryCategory
  const fullFilter = await getContentfulClient(queryParameters).getEntries({
    ...baseFetchObject,
    [primaryCategory ? 'fields.primaryCategory' : 'fields.mainCategory']:
      primaryCategory || mainCategory,
  })

  if (fullFilter?.total > 2) return fullFilter

  //Filter based on storyType only
  const storyTypeFilter = await getContentfulClient(queryParameters).getEntries({
    ...baseFetchObject,
  })

  if (storyTypeFilter?.total > 2) return storyTypeFilter

  //no filters just return up to 3 articles sorted by date
  return getContentfulClient(queryParameters).getEntries(baseFetchObject)
}

export const fetchFullPageContent = async (entryID) => {
  try {
    const [dataPage, dataSection] = await Promise.all([
      queryApolloById(QUERY_PAGE, entryID),
      queryApolloById(QUERY_PAGE_SECTIONS, entryID),
    ])
    const data = normalizeContent(dataPage.data)
    data.page['sectionsCollection'] = normalizeContent(dataSection.data.page.sectionsCollection)
    // encryptionKey is only used for Dashboard login circle inside FAQ
    let mainEncryptionKey
    const response = {
      fields: {
        name: data?.page?.name,
        path: data?.page?.path,
        showPhoneNumber: data?.page?.showPhoneNumber,
        experiment: data?.page?.experiment,
        pageConfig: data?.page?.pageConfig,
        pageDisclosure: processPageDisclosure(data?.page?.pageDisclosure),
        experimentVariation: data?.page?.experimentVariation,
        associatedProduct: data?.page?.associatedProduct,
        pageMetaTitle: data?.page?.pageMetaTitle,
        metaDescription: data?.page?.metaDescription,
        canonicalLinkOverride: data?.page?.canonicalLinkOverride,
        sections: await Promise.all(
          data?.page?.sectionsCollection?.items?.map(async (section) => {
            let sectionContent = await fetchSectionContent(section.sys.id)
            const sectionContents = await Promise.all(
              sectionContent?.pageSection?.sectionContentsCollection?.items.map(async (item) => {
                if (item?.__typename == 'Faqs2') {
                  const FAQData = await fetchFAQData(item?.sys?.id)
                  mainEncryptionKey = FAQData?.encryptionKey
                  return {
                    fields: {
                      ...item,
                      ...FAQData?.data,
                      encryptionKey: FAQData?.encryptionKey,
                    },
                  }
                }
                if (item?.gridItemsCollection) {
                  const updatedGridItems = await Promise.all(
                    item.gridItemsCollection.items.map(async (gridItem) => {
                      switch (gridItem.__typename) {
                        case 'Grid': {
                          let subGrid = await fetchSubGrid(gridItem.sys.id)
                          return {
                            ...gridItem,
                            ...subGrid?.grid,
                          }
                        }

                        case 'CardMarkers': {
                          const cardMarker = await fetchCardMarkers(gridItem.sys.id)
                          return {
                            ...gridItem,
                            ...cardMarker?.cardMarkers,
                          }
                        }
                        case 'StandardCard': {
                          const standardCard = await fetchStandardCard(gridItem.sys.id)
                          return {
                            ...gridItem,
                            ...standardCard?.standardCard,
                          }
                        }
                        case 'MemberStory': {
                          const memberStory = await fetchMemberStory(gridItem.sys.id)
                          return {
                            ...gridItem,
                            ...memberStory?.memberStory,
                          }
                        }
                        case 'MediaField': {
                          const mediaField = await fetchMediaField(gridItem.sys.id)
                          return {
                            ...gridItem,
                            ...mediaField?.mediaField,
                          }
                        }
                        case 'StoryBlockCard': {
                          const storyBlockCard = await fetchStoryBlockCard(gridItem.sys.id)
                          return {
                            ...gridItem,
                            ...storyBlockCard?.storyBlockCard,
                          }
                        }
                        default:
                          return { ...gridItem }
                      }
                    })
                  ).catch((error) => sendApolloAlert(error))
                  return {
                    fields: {
                      ...item,
                      gridItemsCollection: {
                        ...item.gridItemsCollection,
                        items: updatedGridItems,
                      },
                    },
                  }
                }

                if (item?.carouselItemsCollection) {
                  const updatedGridItems = await Promise.all(
                    item.carouselItemsCollection.items.map(async (gridItem) => {
                      switch (gridItem.__typename) {
                        case 'Grid': {
                          const subGrid = await fetchSubGrid(gridItem.sys.id)
                          return {
                            ...gridItem,
                            ...subGrid?.grid,
                          }
                        }
                        case 'CardMarkers': {
                          const cardMarker = await fetchCardMarkers(gridItem.sys.id)
                          return {
                            ...gridItem,
                            ...cardMarker?.cardMarkers,
                          }
                        }
                        case 'StandardCard': {
                          const standardCard = await fetchStandardCard(gridItem.sys.id)
                          return {
                            ...gridItem,
                            ...standardCard?.standardCard,
                          }
                        }
                        case 'MemberStory': {
                          const memberStory = await fetchMemberStory(gridItem.sys.id)
                          return {
                            ...gridItem,
                            ...memberStory?.memberStory,
                          }
                        }

                        default:
                          return { ...gridItem }
                      }
                    })
                  ).catch((error) => sendApolloAlert(error))
                  return {
                    fields: {
                      ...item,
                      carouselItemsCollection: {
                        ...item.carouselItemsCollection,
                        items: updatedGridItems,
                      },
                    },
                  }
                }

                if (item?.textContent?.__typename === 'ContentTypeRichTextTextContent') {
                  const fullRichText = await fetchContentTypeRichText(item?.sys?.id)
                  return { fields: { ...item, textContent: fullRichText.textContent } }
                }

                if (item?.textContent?.__typename === 'ColorRichTextTextContent') {
                  const fullColorRichText = await fetchContentTypeColorRichText(item?.sys?.id)
                  return { fields: fullColorRichText }
                }

                if (
                  item?.questionsCollection?.__typename === 'WwwQuestionGroupQuestionsCollection'
                ) {
                  const updatedQuestionItems = await Promise.all(
                    item.questionsCollection.items.map(async (questionItem) => {
                      const questionGroup = await fetchQuestion(questionItem.sys.id)
                      return {
                        ...questionItem,
                        ...questionGroup?.question,
                      }
                    })
                  ).catch((error) => sendApolloAlert(error))
                  return {
                    fields: {
                      ...item,
                      questionsCollection: {
                        ...item.questionsCollection,
                        items: updatedQuestionItems,
                      },
                    },
                  }
                }

                if (item?.__typename === 'MoloSectionFeature') {
                  const fullMoloSection = await fetchMoloSection(item?.sys?.id)
                  return { fields: fullMoloSection }
                }

                if (item?.__typename === 'StandardCard') {
                  const standardCard = await fetchStandardCard(item?.sys?.id)
                  return { fields: standardCard?.standardCard }
                }

                if (item?.__typename === 'PageSection') {
                  const pageSection = normalizeContent(
                    (await fetchSectionContent(item?.sys?.id))?.pageSection
                  )
                  for (let i = 0; i < pageSection.sectionContentsCollection?.items.length; i++) {
                    const item = pageSection.sectionContentsCollection?.items[i]
                    if (item?.textContent?.__typename === 'ContentTypeRichTextTextContent') {
                      const fullRichText = await fetchContentTypeRichText(item?.sys?.id)
                      pageSection.sectionContentsCollection.items[i].textContent =
                        fullRichText.textContent
                    }
                  }
                  if (pageSection?.relatedContentCollection?.total > 0) {
                    const relatedContentCollection = normalizeContent(
                      (await fetchSectionRelatedContent(item?.sys?.id))?.pageSection
                        ?.relatedContentCollection
                    )
                    pageSection.relatedContentCollection = relatedContentCollection
                  }
                  return { fields: pageSection }
                }
                return { fields: item }
              })
            ).catch((error) => sendApolloAlert(error))
            return {
              fields: {
                ...section,
                sectionContents: sectionContents,
              },
            }
          })
        ).catch((error) => sendApolloAlert(error)),
      },
    }
    return { ...response, mainEncryptionKey }
  } catch (error) {
    sendApolloAlert(error)
    return {
      props: { errorCode: 503, error: error },
    }
  }
}

export const fetchFullLandingPageContent = async (entryID) => {
  const { data } = await queryApolloById(LANDING_PAGE, entryID)
  return {
    fields: {
      name: data?.landingPage?.name,
      path: data?.landingPage?.path,
      showPhoneNumber: data?.landingPage?.showPhoneNumber,
      phoneOverride: data?.landingPage?.phoneOverride,
      experiment: data?.landingPage?.experiment,
      pageConfig: data?.landingPage?.pageConfig,
      pageDisclosure: data?.landingPage?.pageDisclosure,
      experimentVariation: data?.landingPage?.experimentVariation,
      associatedProduct: data?.landingPage?.associatedProduct,
      pageMetaTitle: data?.landingPage?.pageMetaTitle,
      metaDescription: data?.landingPage?.metaDescription,
      canonicalLinkOverride: data?.landingPage?.canonicalLinkOverride,
      sections: await Promise.all(
        data?.landingPage?.sectionsCollection?.items?.map(async (section) => {
          let sectionContent = await fetchSectionContent(section.sys.id)
          const sectionContents = await Promise.all(
            sectionContent?.pageSection?.sectionContentsCollection?.items.map(async (item) => {
              if (item?.__typename == 'Faqs2') {
                const FAQData = await fetchFAQData(item?.sys?.id)
                return {
                  fields: {
                    ...item,
                    ...FAQData?.data,
                  },
                }
              }
              if (item?.gridItemsCollection) {
                const updatedGridItems = await Promise.all(
                  item.gridItemsCollection.items.map(async (gridItem) => {
                    switch (gridItem.__typename) {
                      case 'Grid': {
                        let subGrid = await fetchSubGrid(gridItem.sys.id)
                        return {
                          ...gridItem,
                          ...subGrid?.grid,
                        }
                      }

                      case 'CardMarkers': {
                        const cardMarker = await fetchCardMarkers(gridItem.sys.id)
                        return {
                          ...gridItem,
                          ...cardMarker?.cardMarkers,
                        }
                      }
                      case 'StandardCard': {
                        const standardCard = await fetchStandardCard(gridItem.sys.id)
                        return {
                          ...gridItem,
                          ...standardCard?.standardCard,
                        }
                      }
                      case 'MemberStory': {
                        const memberStory = await fetchMemberStory(gridItem.sys.id)
                        return {
                          ...gridItem,
                          ...memberStory?.memberStory,
                        }
                      }
                      case 'MediaField': {
                        const mediaField = await fetchMediaField(gridItem.sys.id)
                        return {
                          ...gridItem,
                          ...mediaField?.mediaField,
                        }
                      }
                      case 'StoryBlockCard': {
                        const storyBlockCard = await fetchStoryBlockCard(gridItem.sys.id)
                        return {
                          ...gridItem,
                          ...storyBlockCard?.storyBlockCard,
                        }
                      }
                      default:
                        return { ...gridItem }
                    }
                  })
                ).catch((error) => sendApolloAlert(error))
                return {
                  fields: {
                    ...item,
                    gridItemsCollection: {
                      ...item.gridItemsCollection,
                      items: updatedGridItems,
                    },
                  },
                }
              }

              if (item?.carouselItemsCollection) {
                const updatedGridItems = await Promise.all(
                  item.carouselItemsCollection.items.map(async (gridItem) => {
                    switch (gridItem.__typename) {
                      case 'Grid': {
                        const subGrid = await fetchSubGrid(gridItem.sys.id)
                        return {
                          ...gridItem,
                          ...subGrid?.grid,
                        }
                      }
                      case 'CardMarkers': {
                        const cardMarker = await fetchCardMarkers(gridItem.sys.id)
                        return {
                          ...gridItem,
                          ...cardMarker?.cardMarkers,
                        }
                      }
                      case 'StandardCard': {
                        const standardCard = await fetchStandardCard(gridItem.sys.id)
                        return {
                          ...gridItem,
                          ...standardCard?.standardCard,
                        }
                      }
                      case 'MemberStory': {
                        const memberStory = await fetchMemberStory(gridItem.sys.id)
                        return {
                          ...gridItem,
                          ...memberStory?.memberStory,
                        }
                      }

                      default:
                        return { ...gridItem }
                    }
                  })
                ).catch((error) => sendApolloAlert(error))
                return {
                  fields: {
                    ...item,
                    carouselItemsCollection: {
                      ...item.carouselItemsCollection,
                      items: updatedGridItems,
                    },
                  },
                }
              }
              if (item?.textContent?.__typename === 'ContentTypeRichTextTextContent') {
                if (section.uiComponent === 'PoliciesBody') {
                  return { fields: { ...item, textContent: null } }
                }
                const fullRichText = await fetchContentTypeRichText(item?.sys?.id)
                return { fields: { ...item, textContent: fullRichText.textContent } }
              }

              if (item?.textContent?.__typename === 'ColorRichTextTextContent') {
                const fullColorRichText = await fetchContentTypeColorRichText(item?.sys?.id)
                return { fields: fullColorRichText }
              }

              if (item?.__typename === 'MoloSectionFeature') {
                const fullMoloSection = await fetchMoloSection(item?.sys?.id)
                return { fields: fullMoloSection }
              }
              return { fields: item }
            })
          ).catch((error) => sendApolloAlert(error))
          return {
            fields: {
              ...section,
              sectionContents: sectionContents,
            },
          }
        })
      ).catch((error) => sendApolloAlert(error)),
    },
  }
}

export const fetchMoloSection = async (id) => {
  const { data } = await queryApolloById(MOLO_SECTION, id)
  return data?.moloSectionFeature
}

export const fetchSectionContent = async (id) => {
  const { data } = await queryApolloById(PAGE_SECTION, id)
  return data
}

export const fetchFAQData = async (id) => {
  const { data } = await queryApolloById(FAQ_DATA, id)
  const encryptionKey = createRandomKey()
  return { ...data, encryptionKey }
}

export const fetchSectionRelatedContent = async (id) => {
  const { data } = await queryApolloById(PAGE_SECTION_RELATED_CONTENT, id)
  return data
}

export const fetchCardMarkers = async (id) => {
  const { data } = await queryApolloById(CARD_MARKERS, id)
  return data
}

export const fetchStandardCard = async (id) => {
  const { data } = await queryApolloById(STANDARD_CARD, id)
  return data
}
export const fetchMemberStory = async (id) => {
  const { data } = await queryApolloById(MEMBER_STORY, id)
  return data
}

export const fetchQuestion = async (id) => {
  const { data } = await queryApolloById(QUESTION, id)
  return data
}

export const fetchSubGrid = async (id) => {
  const { data } = await queryApolloById(GRID, id)
  return data
}

export const fetchMediaField = async (id) => {
  const { data } = await queryApolloById(MEDIA_FIELD, id)
  return data
}

export const fetchStoryBlockCard = async (id) => {
  const { data } = await queryApolloById(STORY_BLOCK_CARD, id)
  return data
}

// Keep this contentful client call for now as apollo client graphql with contentful does not provide sys.updatedAt field for querying
export const fetchLongformContentXMLByContentTypeField = async (
  contentTypeField,
  fields,
  allEntries = [],
  skip = 0,
  queryParameters = {}
) => {
  let searchObject = {
    content_type: CONTENT_TYPES.LONG_FORM_CONTENT,
    order: '-sys.updatedAt',
    skip: skip,
    limit: CONTENTFUL_LIMIT,
    'fields.contentType': contentTypeField,
    ...fields,
  }
  const searchObjectWithLimit = { ...searchObject }
  let result = await getContentfulClient(queryParameters).getEntries(searchObjectWithLimit)
  const entries = [...allEntries, ...result.items]
  return entries.length < result.total
    ? fetchLongformContentXMLByContentTypeField(
        contentTypeField,
        fields,
        entries,
        skip + CONTENTFUL_LIMIT,
        queryParameters
      )
    : entries
}

export const fetchPressStoryXML = async (
  contentType,
  fields,
  allEntries = [],
  skip = 0,
  queryParameters = {}
) => {
  let searchObject = {
    content_type: contentType,
    order: '-sys.updatedAt',
    skip: skip,
    limit: CONTENTFUL_LIMIT,
    ...fields,
  }
  const searchObjectWithLimit = { ...searchObject }
  let result = await getContentfulClient(queryParameters).getEntries(searchObjectWithLimit)
  const entries = [...allEntries, ...result.items]
  return entries.length < result.total
    ? fetchLongformContentXMLByContentTypeField(
        contentType,
        fields,
        entries,
        skip + CONTENTFUL_LIMIT,
        queryParameters
      )
    : entries
}

export const fetchPageCanonicalAndUpdated = async (page, queryParameters = {}) => {
  let searchObject = {
    content_type: CONTENT_TYPES.PAGE,
    select: 'fields.path,fields.canonicalLinkOverride,sys.updatedAt',
    'fields.path': page,
    order: '-sys.updatedAt',
    skip: 0,
    limit: 1,
  }
  let result = await getContentfulClient(queryParameters).getEntries(searchObject)
  return result.items[0]
}

export const fetchEmergencyBanners = async () => {
  let searchObject = {
    content_type: CONTENT_TYPES.EMERGENCIES_BANNER,
    order: '-sys.updatedAt',
  }
  return await getContentfulClient({}).getEntries(searchObject)
}

export const defaultListArticles = async (
  content_type = CONTENT_TYPES.LONG_FORM_CONTENT,
  skip,
  filter = {},
  orderBy,
  limit = PAGE_LIMIT_DEFAULT
) => {
  // Adding a filter for the website field to ensure we only get the content for the current website
  filter = { ...filter, website: websiteField }
  if (CONTENT_TYPES.LONG_FORM_CONTENT === content_type) {
    if (filter?.search == true) {
      delete filter?.video
      delete filter.contentType
      delete filter?.primaryCategory_in
      delete filter?.search
      const { data } = await queryApolloByFilter(LONG_FORM_CONTENT(2000, 0, orderBy), filter)
      const longFormContentCollection = normalizeContent(data?.longFormContentCollection.items)
      const total = longFormContentCollection.length
      const paginatedArticles = longFormContentCollection.slice(skip, skip + limit)
      return { total, items: paginatedArticles }
    }
    if (filter?.video == true || filter.contentType == 'videos') {
      delete filter?.search
      delete filter?.video
      delete filter.contentType
      delete filter?.primaryCategory_in
      const { data } = await queryApolloByFilter(LONG_FORM_CONTENT(2000, 0, orderBy), filter)
      const longFormContentCollection = normalizeContent(
        data?.longFormContentCollection.items
      ).filter((article) => {
        return article.mainImage.__typename === 'VideoPlayer'
      })
      const total = longFormContentCollection.length
      const paginatedArticles = longFormContentCollection.slice(skip, skip + limit)
      return { total, items: paginatedArticles }
    }
    delete filter?.search
    delete filter?.video
    const { data } = await queryApolloByFilter(LONG_FORM_CONTENT(limit, skip, orderBy), filter)
    return data?.longFormContentCollection
  }
  if (CONTENT_TYPES.PRESS_STORY === content_type) {
    delete filter?.search
    const { data } = await queryApolloByFilter(PRESS_STORY(limit, skip), filter)
    return data?.pressStoryCollection
  }
}
export const fetchContentTypeRichText = async (id) => {
  const { data } = await queryApolloById(CONTENT_TYPE_RICH_TEXT, id)
  return data?.contentTypeRichText
}

export const fetchContentTypeColorRichText = async (id) => {
  const { data } = await queryApolloById(COLOR_RICH_TEXT, id)
  return data?.colorRichText
}

export const fetchEmailForm = async (id) => {
  const { data } = await apolloClient.query({
    query: EMAIL_FORM,
    variables: {
      id: id,
    },
  })
  return data?.emailForm
}

export const fetchGlobalVariables = async (queryParameters = {}) => {
  return getContentfulClient(queryParameters).getEntries({
    content_type: CONTENT_TYPES.JSON_FIELD,
    'fields.name': 'Frontend Global Variables',
    include: NESTED_REFERENCE_RESOLUTION_DEPTH,
    locale: LANGUAGES.DEFAULT,
  })
}

export const fetchPhoneData = async (queryParameters = {}) => {
  return getContentfulClient(queryParameters).getEntries({
    content_type: CONTENT_TYPES.JSON_FIELD,
    'fields.name': 'Phone Numbers Data',
    include: NESTED_REFERENCE_RESOLUTION_DEPTH,
    locale: LANGUAGES.DEFAULT,
  })
}

export const fetchSpecificHeaderCtaData = async (queryParameters = {}) => {
  return getContentfulClient(queryParameters).getEntries({
    content_type: CONTENT_TYPES.JSON_FIELD,
    'fields.name': 'Specific Header CTA Data',
    include: NESTED_REFERENCE_RESOLUTION_DEPTH,
    locale: LANGUAGES.DEFAULT,
  })
}

export const fetchAchieveConsentBanner = async (queryParameters = {}) => {
  return getContentfulClient(queryParameters).getEntries({
    content_type: 'richText',
    'fields.fieldName': 'achieveConsentBanner',
    include: NESTED_REFERENCE_RESOLUTION_DEPTH,
    locale: LANGUAGES.DEFAULT,
  })
}

export const fetchAuthors = async (skip) => {
  return getContentfulClient().getEntries({
    content_type: CONTENT_TYPES.AUTHOR,
    order: 'fields.authorName',
    skip: skip,
    limit: AUTHOR_LIMIT_DEFAULT,
  })
}

/**
 * Fetches articles by a specific author, paginated by the specified page number.
 *
 * @param {Object} params - The parameters object.
 * @param {number} [params.page=1] - The page number for pagination.
 * @param {string} params.authorName - The name of the author to fetch articles for.
 * @returns {Promise<Object>} A promise that resolves to an object containing the total number of
 *                            articles, the number of pages, and the list of articles for the
 *                            specified page.
 */
export const fetchArticlesByAuthor = async ({ page = 1, authorName }) => {
  const defaultResponse = {
    totalArticles: 0,
    pageCount: 0,
    articles: [],
  }

  if (!authorName) return defaultResponse
  let response

  try {
    response = await queryApollo(ARTICLES_BY_AUTHOR, { authorName })
  } catch (error) {
    sendApolloAlert(error)
    return defaultResponse
  }

  const { data: { longFormContentCollection } = {} } = response

  const totalArticles = longFormContentCollection?.total

  if (!totalArticles) return defaultResponse

  const allAuthorArticlesList = longFormContentCollection?.items

  // Sort all articles by publish data, newest to oldest
  const sortedArticleList = sortEntriesByPublishDate(normalizeContent(allAuthorArticlesList))

  const skipMultiplier = parseInt(page - 1)
  // don't skip anything by default
  let skip = 0

  if (skipMultiplier && skipMultiplier > 0) {
    // Determine how many articles to skip based on page/multiplier
    skip = skipMultiplier * AUTHOR_LIMIT_ARTICLES_DEFAULT
  }

  return {
    totalArticles,
    pageCount: Math.ceil(totalArticles / AUTHOR_LIMIT_ARTICLES_DEFAULT),
    articles: sortedArticleList.slice(skip, skip + AUTHOR_LIMIT_ARTICLES_DEFAULT),
  }
}

export const sortEntriesByPublishDate = (entries) =>
  entries.sort((a, b) => new Date(b.publishDate) - new Date(a.publishDate))

export const fetchAuthorBySlug = async (slug) => {
  return fetchEntry(slug, CONTENT_TYPES.AUTHOR)
}

export const fetchAuthorByAuthorName = async (authorName) => {
  return getContentfulClient().getEntries({
    content_type: CONTENT_TYPES.AUTHOR,
    'fields.authorName': authorName,
  })
}

export const fetchGlobalLazyLoadingSectionMapping = async () => {
  const { data } = await queryApollo(JSON_FIELD)
  return data?.jsonFieldCollection?.items?.[0]?.jsonContent ?? {}
}

export const fetchArticlesCategorySection = async (categoryName) => {
  const fieldName = `main-list-articles-learn-${getSlug(categoryName)}`
  const { data } = await queryApolloByFilter(ARTICLE_CATEGORY_SECTION, {
    fieldName,
    website: websiteField,
  })
  return normalizeContent(data?.pageSectionCollection)
}

export const fetchArticlesNavSection = async (fieldName) => {
  const { data } = await queryApolloByFilter(ARTICLE_NAV_SECTION, {
    fieldName,
    website: websiteField,
  })
  return normalizeContent(data?.pageSectionCollection)
}

export const fetchLearnCategoriesFull = async () => {
  const { websiteField } = getPageContentTypes()
  const { data } = await queryApolloByFilter(LEARN_CATEGORIES_FULL, {
    where: { website: websiteField },
  })
  const reducerItems = (elements, contentType) => {
    return [
      ...new Set(
        elements
          .filter((element) => element.contentType === contentType)
          .map((element) => element.primaryCategory)
      ),
    ]
  }
  const reducerSubitems = (elements, contentType) => {
    return [
      ...new Set(
        [
          ...new Set(
            elements
              .filter((element) => element.contentType === contentType)
              .map((element) => element.secondaryCategory ?? [])
          ),
        ].flat()
      ),
    ]
  }
  const listCategories = {
    [ARTICLES_CONTENT_TYPES.ARTICLE]: [
      'Financial Education',
      ...reducerItems(
        normalizeContent(data?.longFormContentCollection?.items),
        ARTICLES_CONTENT_TYPES.ARTICLE
      ),
    ],
    [ARTICLES_CONTENT_TYPES.CORPORATE_BLOG]: [
      'Insights & News',
      ...reducerItems(
        normalizeContent(data?.longFormContentCollection?.items),
        ARTICLES_CONTENT_TYPES.CORPORATE_BLOG
      ),
    ],
    [ARTICLES_CONTENT_TYPES.CONSUMER_BLOG]: [
      'Money & Lifestyle',
      ...reducerItems(
        normalizeContent(data?.longFormContentCollection?.items),
        ARTICLES_CONTENT_TYPES.CONSUMER_BLOG
      ),
    ],
  }
  const listSecondaryCategories = {
    [ARTICLES_CONTENT_TYPES.ARTICLE]: [
      ...reducerSubitems(
        normalizeContent(data?.longFormContentCollection?.items),
        ARTICLES_CONTENT_TYPES.ARTICLE
      ),
    ],
    [ARTICLES_CONTENT_TYPES.CORPORATE_BLOG]: [
      ...reducerSubitems(
        normalizeContent(data?.longFormContentCollection?.items),
        ARTICLES_CONTENT_TYPES.CORPORATE_BLOG
      ),
    ],
    [ARTICLES_CONTENT_TYPES.CONSUMER_BLOG]: [
      ...reducerSubitems(
        normalizeContent(data?.longFormContentCollection?.items),
        ARTICLES_CONTENT_TYPES.CONSUMER_BLOG
      ),
    ],
  }
  return { listCategories, listSecondaryCategories }
}

export const searchCategoryDirectory = async (slug) => {
  let categoryName,
    allPage = false,
    contentType = null,
    isSubcategory = false
  if (slug === 'videos')
    return { categoryName: null, contentType: 'videos', allPage, isSubcategory }

  if (slug === 'search')
    return { categoryName: null, contentType: 'Article', allPage: true, isSubcategory: false }

  const { listCategories, listSecondaryCategories } = await fetchLearnCategoriesFull()

  let i = 0
  while (!categoryName && i < Object.keys(listCategories).length) {
    const articleType = listCategories[Object.keys(listCategories)[i]]
    let j = 0
    while (!categoryName && j < articleType.length) {
      const category = articleType[j]
      if (getSlug(category) === slug) {
        contentType = Object.keys(listCategories)[i]
        categoryName = category
        allPage = j === 0
      }
      j++
    }

    const articleTypeSubcategory = listSecondaryCategories[Object.keys(listSecondaryCategories)[i]]
    let k = 0
    while (!categoryName && k < articleTypeSubcategory.length) {
      const category = articleTypeSubcategory[k]
      if (getSlug(category) === slug) {
        contentType = Object.keys(listCategories)[i]
        categoryName = category
        isSubcategory = true
      }
      k++
    }

    i++
  }

  return { categoryName, allPage, contentType, isSubcategory }
}

/**
 * Processes the given page disclosure object from GraphQL query by merging embedded entries
 * This function checks if the `pageDisclosure` object contains any embedded entries and, if so,
 * merges these entries into the existing JSON structure.
 * It checks for nodes of type `embedded-entry-block` and replaces their `target` data
 * with the corresponding embedded entry fields.
 *
 * @param {Object} pageDisclosure - The page disclosure object to process.
 * @returns {Object|null} - Returns the processed page disclosure object with merged embedded
 * entries in the content, or `null` if `pageDisclosure` is not provided or has no embedded entries.
 **/

const processPageDisclosure = (pageDisclosure) => {
  if (!pageDisclosure) return null

  const jsonContent = pageDisclosure.json.content || []
  const embeddedEntries = pageDisclosure.links.entries.block || []

  if (!embeddedEntries.length) {
    return pageDisclosure
  }
  const entryMap = new Map()
  embeddedEntries.forEach((entry) => {
    entryMap.set(entry.sys.id, entry)
  })

  const mergeEmbeddedEntries = (content) => {
    return content.map((node) => {
      const entryId = node?.data?.target?.sys?.id
      if (node.nodeType === 'embedded-entry-block' && entryId) {
        const embeddedEntry = entryMap.get(entryId)
        return {
          ...node,
          data: {
            ...node?.data,
            target: {
              ...node?.data?.target,
              fields: {
                ...embeddedEntry,
              },
            },
          },
        }
      }
      return node
    })
  }

  const mergedContent = mergeEmbeddedEntries(jsonContent)

  return {
    ...pageDisclosure,
    json: {
      ...pageDisclosure.json,
      content: mergedContent,
    },
  }
}

export const mainProductConfig = {
  'website-wfs': {
    MainName: 'Weekly Financial Solution',
    MainProduct: 'Weekly Financial Solution',
    MainProductHyphen: 'Weekly-Financial-Solution',
    PublicURL: 'https://learn.weeklyfinancialsolutions.com/',
    SocialMediaLinks: ['https://www.facebook.com/weeklyfinancialsolutions/'],
    LogoURL:
      'https://d9hhrg4mnvzow.cloudfront.net/learn.weeklyfinancialsolutions.com/open-program-fdr/a74f9794-wfs-logo-dark_106u02f000000000000028.png',
    FooterLogo: '/wfs-logo-dark.png',
    HeaderLogo: 'wfs-logo-dark',
    URL: 'Weeklyfinancialsolutions.com',
    headerCta: false,
    showSignIn: false,
    showPhone: false,
    showNavFooter: false,
  },
  'website-tenant-2': {
    MainName: 'Freedom Debt Relief',
    MainProduct: 'Freedom Debt Relief',
    MainProductHyphen: 'freedom-debt-relief',
    PublicURL: 'https://www.freedomdebtrelief.com',
    SocialMediaLinks: [
      'https://x.com/FreedomDebt',
      'https://www.facebook.com/pages/Freedom-Debt-Relief/69869352985',
      'https://www.youtube.com/freedomdebtrelief',
      'https://www.linkedin.com/company/freedom-debt-relief',
      'https://www.instagram.com/achievecom',
    ],
    LogoURL: 'https://www.freedomdebtrelief.com/next-assets/icons/fdr-logo.svg',
    FooterLogo: '/fdr-logo.svg',
    HeaderLogo: 'fdr-logo.svg',
    URL: 'Freedomdebtrelief.com',
    headerCta: false,
    showSignIn: false,
    showPhone: false,
    showNavFooter: true,
  },
  achieve: {
    MainName: 'Achieve',
    MainProduct: 'Achieve Web',
    MainProductHyphen: 'Achieve-Web',
    PublicURL: 'https://www.achieve.com',
    SocialMediaLinks: [
      'https://twitter.com/achievecom',
      'https://www.facebook.com/achievecom',
      'https://www.youtube.com/@Achievecom',
      'https://www.linkedin.com/company/achievecareers',
      'https://www.tiktok.com/@achievecom_',
      'https://www.instagram.com/achievecom',
    ],
    LogoURL: 'https://www.achieve.com/2022_Achieve_Logo_RGB.svg?w=3840',
    FooterLogo: '/2022_Achieve_Logo_RGB.svg',
    HeaderLogo: '2022_Achieve_Logo_RGB',
    URL: 'Achieve.com',
    headerCta: true,
    showSignIn: true,
    showPhone: true,
    showNavFooter: true,
  },
}
